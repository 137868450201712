img {
  max-width: auto;
  height: auto;
  left: 0px;
  right: 0px;
}

.logo {
  height: 5vmin;
  pointer-events: none;
}
