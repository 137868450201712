* {
  margin: 0;
  padding: 0;
  box-sizing: 0;
  font-family: "Heebo", Arial, Helvetica, sans-serif;
}

div.top_tittle {
  margin: 5px;
  text-align: left;
  width: 100px;
  height: auto;
}

div.paragraph {
  text-align: justify;
}

img {
  max-width: 100%;
  height: auto;
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}
